import * as React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import SearchFilter from "../../components/Helpers/SearchFilter";

const results = (props: any) => {
  return (
    <Layout title={"Search for an Organization"}>
      <SEO title="Search for an Organization" />
      <SearchFilter state={props.location.state}></SearchFilter>
    </Layout>
  );
};

export default results;
